import React from 'react'
import { Helmet } from 'react-helmet'

import { graphql } from 'gatsby'
import Layout from '../components/layout'

// Component imports
import ProjectHeader from '../components/project/project-header'
import ProjectContent from '../components/project/project-content'

export default function Template({ data }) {
  const { markdownRemark: project } = data
  const { title, url } = project.frontmatter
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Work | Hans Hanken</title>
      </Helmet>
      <ProjectHeader project={project} />
      <ProjectContent project={project} />
    </Layout>
  )
}

export const projectQuery = graphql`
  query ProjectByPath($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      summaryImage {
        childImageSharp {
          fixed(width: 600) {
            src
          }
        }
      }
      frontmatter {
        title
        slug
        projectUrl
        shortDescription
        summary
        summaryHeader
        summaryImage {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
        companyLogo
        paragraphs {
          paragraphHeader
          paragraphHtml
          paragraphImage {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
          placement
        }
      }
    }
  }
`
