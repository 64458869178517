import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

// Style imports
import './styles/project-content.scss'

const ProjectContent = ({
  project: {
    frontmatter: { summary, summaryHeader, summaryImage, paragraphs },
  },
  project,
}) => (
  <div className="project-content container">
    {console.log(project)}
    <div className="project-content-section project-content-summary left-align load-animation in-from-bot">
      <div className="project-content-img-container">
        <Img
          fluid={summaryImage.childImageSharp.fluid}
          className="img-fluid project-content-img"
          alt="Taken from the project website"
        />
      </div>
      <div className="project-content-text">
        <h2 className="header-2 summary-header">{summaryHeader}</h2>
        <p className="text">{summary}</p>
      </div>
    </div>
    {paragraphs.map(({ paragraphHeader, paragraphHtml, paragraphImage, placement }, idx) => (
      <div
        className={`project-content-section scroll-animation ${
          placement === 'right' ? 'right-align in-from-right' : 'left-align in-from-left'
        }`}
        key={idx}
      >
        {paragraphImage && (
          <div className="project-content-img-container">
            <Img
              fluid={paragraphImage.childImageSharp.fluid}
              className="img-fluid project-content-img"
              objectFit="cover"
              objectPosition="50% 50%"
              alt="Taken from the project website"
            />
          </div>
        )}

        <div className="project-content-text">
          <h2 className="header-2">{paragraphHeader}</h2>
          <div dangerouslySetInnerHTML={{ __html: paragraphHtml }} />
        </div>
      </div>
    ))}
    {/* <div className="project-content-section left-align load-animation in-from-bot">
      <div className="project-content-img-container">
        <img src={sectionOneImage} className="img-fluid project-content-img" />
      </div>
      <div className="project-content-text project-content-text-right">
        <h2 className="header-2">{sectionOneHeader}</h2>
        <MarkdownContent content={sectionOneText} />
      </div>
    </div>

    <div className="project-content-section right-align scroll-animation in-from-right">
      <div className="project-content-text project-content-text-left">
        <h2 className="header-2">{sectionTwoHeader}</h2>
        <MarkdownContent content={sectionTwoText} />
      </div>
      <div className="project-content-img-container">
        <img src={sectionTwoImage} className="img-fluid project-content-img" />
      </div>
    </div>

    <div className="project-content-section left-align scroll-animation in-from-left">
      <div className="project-content-img-container">
        <img src={sectionThreeImage} className="img-fluid project-content-img" />
      </div>
      <div className="project-content-text project-content-text-right">
        <h2 className="header-2">{sectionThreeHeader}</h2>
        <MarkdownContent content={sectionThreeText} />
      </div>
    </div> */}
  </div>
)

ProjectContent.propTypes = {
  project: PropTypes.object,
}

export default ProjectContent
