import React, { useContext, useEffect } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'

// Style imports
import './styles/project-header.css'

// Media imports
import Divider from '../../../assets/svgs/dividers/splash-bot.svg'
import OpenUrlIcon from '../../../assets/svgs/icons/ic-open-url.svg'
import SoftArrow from '../../../assets/svgs/icons/ic-arrow-soft.svg'

// Context imports
import { GlobalStateContext, GlobalDispatchContext } from '../context/global-context-provider'

const ProjectHeader = ({
  project: {
    frontmatter: { title, projectUrl, slug, shortDescription },
  },
  project,
}) => {
  const dispatch = useContext(GlobalDispatchContext)

  useEffect(() => {}, [slug])

  const navigateToPrev = () => {
    dispatch({ type: 'GO_PREV_PROJECT' })
  }

  const navigateToNext = () => {
    dispatch({ type: 'GO_NEXT_PROJECT' })
    // Navigate to sub-page
  }

  return (
    <div>
      <div className="project-header-top container">
        <div className="project-header-nav load-animation fade-in">
          <SoftArrow className="project-nav-arrow flip-horizontal" onClick={navigateToPrev} />
          <div className="project-header-center">
            <h1 className="header-1 no-padding light-text center-text">{title}</h1>
            {projectUrl && (
              <a href={projectUrl} target="_blank" rel="noopener noreferrer" className="hover-animation scale-up">
                <OpenUrlIcon className="icon-green open-url-icon load-animation shake-it" />
              </a>
            )}
          </div>
          <SoftArrow className="project-nav-arrow" onClick={navigateToNext} />
        </div>
        <p className="text light-text project-header-short-description load-animation fade-in">{shortDescription}</p>
      </div>

      <Divider />
    </div>
  )
}

ProjectHeader.propTypes = {
  project: PropTypes.object,
}

export default ProjectHeader
